<template>
  <div>
    <div class="compont_p">积分兑换记录</div>

    <el-form :inline="true" style="margin-top: 20px">
      <el-form-item label="商品名称:">
        <el-input v-model="kw" clearable></el-input>
      </el-form-item>
      <el-form-item label="兑换时间:">
        <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
      </el-form-item>
      <el-form-item label="发放状态:">
        <el-select v-model="status" placeholder="请选择" clearable>
          <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="custombtn" @click="getPoints">筛选</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData">
      <el-table-column prop="title" label="商品名称" align="center" />
      <el-table-column prop="score" label="消费积分" align="center" />
      <el-table-column prop="time_str" label="兑换时间" align="center" />
      <el-table-column prop="shr" label="收货人" align="center" />
      <el-table-column prop="shdh" label="收货电话" align="center" />
      <el-table-column prop="shdz" label="收货地址" align="center" />
      <el-table-column prop="status_str" label="发放状态" align="center" />
    </el-table>
    <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      tableData: [],
      options: [
        { id: "1", title: "待发放" },
        { id: "2", title: "已发放" },
        { id: "3", title: "已作废" },
      ],
      kw: "", //商品名称
      time: "",
      status: "",
      total: 0,
      page: 1,
      page_size: 10,
    };
  },
  created() {
    this.getPoints();
    // this.$api("account.getPointsffzt").then((res) => {
    //   this.options = res.data;
    // });
  },
  methods: {
    getPoints() {
      let start_time = "";
      let end_time = "";
      if (this.time && this.time.length > 0) {
        let Atime = Date.parse(new Date(this.time[0])) / 1000;
        let Btime = Date.parse(new Date(this.time[1])) / 1000;
        if (Atime > Btime) {
          end_time = Atime;
          start_time = Btime;
        } else {
          end_time = Btime;
          start_time = Atime;
        }
      }
      this.$api("account.getPointsRecord", {
        kw: this.kw,
        time_start: start_time,
        time_end: end_time,
        status: this.status,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getPoints();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getPoints();
    },
  },
};
</script>
